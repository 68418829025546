import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/views/Dashboard";
import Login from "@/views/auth/Login";
import VueJwtDecode from "vue-jwt-decode";
import ShowCustomer from "@/views/customer/ShowCustomer";
import Templates from "@/views/templates/Templates.vue";
import CreateUpdateTemplateCategory from "@/views/templates/CreateUpdateTemplateCategory.vue";
import CreateUpdateTemplate from "@/views/templates/CreateUpdateTemplate.vue";
import DocumentFileCategories from "@/views/document/DocumentFileCategories.vue";
import CreateUpdateDocumentFileCategory from "@/views/document/CreateUpdateDocumentFileCategory.vue";
import PartnerOffers from "@/views/partner-offers/PartnerOffers.vue";
import CreateUpdatePartnerOffer from "@/views/partner-offers/CreateUpdatePartnerOffer.vue";
import Stats from "@/views/Stats.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: {
            name: 'Dashboard'
        }
    },
    {
        path: "/dashboard",
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            routeType: 'Dashboard',
            title: "Immolert Admin Dashboard"
        }
    },
    {
        path: "/stats",
        name: 'Stats',
        component: Stats,
        meta: {
            routeType: 'Stats',
            title: "Immolert Admin Stats"
        }
    },
    {
        path: "/customer/:id",
        name: 'ShowCustomer',
        component: ShowCustomer,
        meta: {
            routeType: 'Customer',
            title: "Immolert Admin Kundenansicht"
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: "Immolert Login"
        }
    },
    {
        path: '/partner-offers',
        name: 'PartnerOffers',
        component: PartnerOffers,
        meta: {
            title: "Immolert Partnerangebote"
        }
    },
    {
        path: '/create-partner-offer',
        name: 'CreatePartnerOffers',
        component: CreateUpdatePartnerOffer,
        meta: {
            title: "Immolert Partnerangebot erstellen"
        }
    },
    {
        path: '/edit-partner-offer/:id',
        name: 'EditPartnerOffers',
        component: CreateUpdatePartnerOffer,
        meta: {
            title: "Immolert Partnerangebot bearbeiten"
        }
    },
    {
        path: '/templates',
        name: 'Templates',
        component: Templates,
        meta: {
            title: "Immolert Vorlagen"
        }
    },
    {
        path: '/create-template-category',
        name: 'CreateTemplateCategory',
        component: CreateUpdateTemplateCategory,
        meta: {
            title: "Immolert Vorlagenkategorie erstellen"
        }
    },
    {
        path: '/create-template',
        name: 'CreateTemplate',
        component: CreateUpdateTemplate,
        meta: {
            title: "Immolert Vorlage erstellen"
        }
    },
    {
        path: '/update-template-category/:id',
        name: 'UpdateTemplateCategory',
        component: CreateUpdateTemplateCategory,
        meta: {
            title: "Immolert Vorlagenkategorie bearbeiten"
        }
    },
    {
        path: '/update-template/:id',
        name: 'UpdateTemplate',
        component: CreateUpdateTemplate,
        meta: {
            title: "Immolert Vorlage bearbeiten"
        }
    }, {
        path: '/document-file-categories',
        name: 'DocumentFileCategories',
        component: DocumentFileCategories
    }, {
        path: '/create-document-file-categories',
        name: 'CreateUpdateDocumentFileCategory',
        component: CreateUpdateDocumentFileCategory
    }, {
        path: '/document-file-categories/:id',
        name: 'UpdateDocumentFileCategory',
        component: CreateUpdateDocumentFileCategory
    }
];

const router = new VueRouter({routes});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/assets/login-background.jpg'];
    const authRequired = !publicPages.includes(to.path) && to.name !== "ObtainPassword";

    const loggedIn = localStorage.getItem('token');
    let token = loggedIn !== null && loggedIn !== undefined ? VueJwtDecode.decode(loggedIn) : null;

    if ((!loggedIn || !token.resource_access["immolert-app"].roles.includes("ADMIN")) && to.path !== "/login") {
        next('/login');
    }

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn && to.path !== "/login") {
        next('/login');
    } else {
        next();
    }
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || "Immolert";
    });
});

export default router