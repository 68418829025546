<template>
  <b-container>
    <div class="content">
      <b-card header="Kunden (Chronologisch sortiert)">

        <b-row class="mb-3">
          <b-col md="4">
            <b-form-group label="Registriert von">
              <b-form-datepicker v-model="filters.createdFrom" placeholder="Datum wählen"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Registriert bis">
              <b-form-datepicker v-model="filters.createdTo" placeholder="Datum wählen"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Aktiv">
              <b-form-checkbox v-model="filters.active">Nur aktive Nutzer anzeigen</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-form-group label="Suche">
              <b-form-input v-model="filters.search" placeholder="Nach Name oder E-Mail suchen..."/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="primary" @click="applyFilters">Filter anwenden</b-button>&nbsp;&nbsp;
            <b-button variant="secondary" @click="resetFilters">Filter zurücksetzen</b-button>
          </b-col>
        </b-row>
        <br/>
        <b-table class="card-table" striped hover :no-local-sorting="true" :items="items" :fields="fields">
          <template #cell(active)="data">
            <b-checkbox switch v-model="data.item.active" @change="toggleUser(data.item)"/>
          </template>
          <template #cell(actions)="data" class="sm-col">
            <b-button :to="{name:'ShowCustomer', params: {'id':data.item.id}}"
                      size="sm" variant="primary"
                      v-b-tooltip="'Klicken Sie hier um sich den Kunden anzeigen zu lassen.'">
              <b-icon icon="eye" font-scale="1"></b-icon>
            </b-button>
          </template>
          <template #cell(created)="data">
            {{ data.item.created | germanDateTime }}
          </template>
        </b-table>
        <RouterlessPageable v-if="users != null" :pageable="users" @change="loadUsers"/>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import VueJwtDecode from "vue-jwt-decode";
import HTTP from "@/http";
import RouterlessPageable from "@/components/RouterlessPageable";
import messageService from "@/services/message.service";

export default {
  name: 'Dashboard',
  components: {RouterlessPageable},
  data() {
    return {
      token: VueJwtDecode.decode(localStorage.getItem("token")),
      stats: {},
      filters: {
        createdFrom: null,
        createdTo: null,
        active: true,
        search: null,
      },
      fields: [
        {
          key: 'created',
          label: 'Registriert'
        },
        {
          key: 'prename',
          label: 'Vorname'
        },
        {
          key: 'lastname',
          label: 'Nachname'
        },
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'active',
          label: 'Aktiv'
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      items: [],
      users: null
    }
  },
  methods: {
    applyFilters: function () {
      this.loadUsers();
    },
    resetFilters: function () {
      this.filters = {
        createdFrom: null,
        createdTo: null,
        active: true,
        search: null,
      }
      this.loadUsers();
    },
    toggleUser: function (user) {
      HTTP.put("/users/active/" + user.id).then(() => {
        messageService.success("Toggle done", "Nutzer aktiviert/deaktiviert");
      })
    },
    loadUsers: function (page = 0) {

      const params = {};

      Object.keys(this.filters).forEach((key) => {
        if (this.filters[key] !== null && this.filters[key] !== '') {
          params[key] = this.filters[key];
        }
      });

      HTTP.get("/users?sort=_id,desc&page=" + page, {
        params: {
          ...params,
          sort: '_id,desc',
          page: page,
        },
        headers: {
          'Accept': 'application/json',
        },
      }).then(response => {
        this.items = response.data.content;
        this.users = response.data;
      });
    }
  },
  created() {
    this.loadUsers();
  }
}
</script>
<style scoped>

</style>