<template>
  <b-container>
    <div class="content">
      <b-row cols="4">
        <b-col>
          <UserAggregation v-if="stats" text="Gebäude" :has-not="stats.stats.buildings.hasNot"
                           :has="stats.stats.buildings.has" :average="stats.stats.buildings.average"/>
        </b-col>
        <b-col>
          <UserAggregation v-if="stats" text="Einheiten" :has-not="stats.stats.units.hasNot"
                           :has="stats.stats.units.has"
                           :average="stats.stats.units.average"/>
        </b-col>
        <b-col>
          <UserAggregation v-if="stats" text="BKA" :has-not="stats.stats.billingOfIncidentals.hasNot"
                           :has="stats.stats.billingOfIncidentals.has"
                           :average="stats.stats.billingOfIncidentals.average"/>
        </b-col>
        <b-col>
          <UserAggregation v-if="stats" text="Dokumente" :has-not="stats.stats.documents.hasNot"
                           :has="stats.stats.documents.has" :average="stats.stats.documents.average"/>
        </b-col>
        <b-col>
          <UserAggregation v-if="stats" text="Zählerstände" :has-not="stats.stats.counterValues.hasNot"
                           :has="stats.stats.counterValues.has" :average="stats.stats.counterValues.average"/>
        </b-col>
        <b-col>
          <b-card class="text-center">
            <h4>Aktive</h4><br/>
            {{ stats.activeCount }}
          </b-card>
        </b-col>
        <b-col>
          <b-card class="text-center">
            <h4>Insgesamt</h4><br/>
            {{ stats.count }}
          </b-card>
        </b-col>
        <b-col>
          <b-card class="text-center">
            <h4>Gekündigte</h4><br/>
            {{ stats.canceledCount }}
          </b-card>
        </b-col>
        <b-col>
          <b-card class="text-center">
            <h4>30 Tage</h4><br/>
            {{ stats.last30DaysCount }}
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import VueJwtDecode from "vue-jwt-decode";
import HTTP from "@/http";
import UserAggregation from "@/components/UserAggregation.vue";

export default {
  name: 'Dashboard',
  components: {UserAggregation},
  data() {
    return {
      token: VueJwtDecode.decode(localStorage.getItem("token")),
      stats: {},
      users: null
    }
  },
  methods: {},
  created() {
    HTTP.get("/admin/users").then(response => {
      this.stats = response.data;
    });
  }
}
</script>
<style scoped>

</style>