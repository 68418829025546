<template>
  <div>
    <b-card class="text-center">
      <h4>{{ $props.text }}</h4><br/>
      <b-row>
        <b-col>👌<br/>{{$props.has}}</b-col>
        <b-col>🚫<br/>{{$props.hasNot}}</b-col>
        <b-col>⌀<br/>{{ $props.average.toFixed(2).replace('.', ',') }}</b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    average: {
      type: Number,
      required: true
    },
    has: {
      type: Number,
      required: true
    },
    hasNot: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped>
</style>